import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { tap, first } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import { DictionaryItem, DictionaryItemDefinition, DictionarySimplePageComponent } from 'src/app/shared/components/dictionary-simple-page/dictionary-simple-page.component';
import { CommonNoteViewModel, EsraPipingAPIClient, ServiceDetailViewModel } from 'src/app/shared/models/autogenerated-piping';
import { MatDialog } from '@angular/material/dialog';
import { ServiceNoteUsageComponent } from '../dialogs/service-note-usage/service-note-usage.component';
import { DictionaryDeleteComponent } from '../dialogs/dictionary-delete/dictionary-delete.component';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { faInfoCircle, faSearch, faPlusCircle, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { InsightsService } from 'src/app/shared/services/insights.service';

@Component({
  selector: 'app-commonnotes',
  templateUrl: 'common-notes.component.html',
  styleUrls: ['common-notes.component.scss']
})
export class CommonnotesComponent implements OnInit {
  url = 'piping/commonNoteAddEdit';

  currentPage = 0;
  totalCount: number;
  lastPage: number;
  pageSize = 25;
  pageSizes = [2, 5, 10, 25, 50, 100];
  
  dataSource = new MatTableDataSource<CommonNoteViewModel>();
  displayedColumns = ['ID', 'Note', 'Active/Inactive', 'Actions'];

  isShowAllToggled = false;
  searching$ = new BehaviorSubject<boolean>(false);
  @ViewChild('searchInput') searchInput: ElementRef;

  faSearch = faSearch;
  faPlusCircle = faPlusCircle;
  faInfo = faInfoCircle;
  faEdit = faPen;
  faTrashCan = faTrash;

  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private snackBarService: SnackBarService,
    private insightsService: InsightsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.searchAll();
  }

  protected initializeDictionaryDefinition(): DictionaryItemDefinition<ServiceDetailViewModel>{
      return {
        displayStatusColumn: true,
        dictionaryItems: [ new DictionaryItem('ID', 'codeId' ),
          new DictionaryItem('Note', 'note', 'extended')
        ]
      };
  }
    
  searchAll() {
    this.esraApiClient.getAllCurrentCommonNotesWithReferenceInfo(this.pageSize, this.currentPage, this.isShowAllToggled)
      .pipe(
        indicate(this.searching$),
        first(),
        tap(commonNotes => {
          this.dataSource.data = commonNotes;
        })
      ).subscribe();

    this.esraApiClient.getCurrentCommonNoteCount(this.isShowAllToggled)
      .pipe(
        tap(result => {
          this.totalCount = result;
        })
      ).subscribe();
  }

  setSearchValueAndSearch(event) {
    if (event.keyCode === 13) {
      if (event.target.value === '') {
        this.clearSearchText();
      } else {
        this.esraApiClient.searchCurrentCommonNotes(event.target.value, this.pageSize, this.currentPage, this.isShowAllToggled)
        .pipe(
          indicate(this.searching$),
          )
          .subscribe((commonNotes) => {
            this.dataSource.data = commonNotes;
            this.totalCount = commonNotes.length;
          });
      }
    }
  }

  search() {
    this.esraApiClient.searchCurrentCommonNotes(this.searchInput.nativeElement.value, this.pageSize, this.currentPage, this.isShowAllToggled)
    .pipe(
      indicate(this.searching$),
      )
        .subscribe((commonNotes) => {
          this.dataSource.data = commonNotes;
          this.totalCount = commonNotes.length;
        });

    this.esraApiClient.getCurrentCommonNoteCount(this.isShowAllToggled)
      .pipe(
        tap(result => {
          this.totalCount = result;
        })
      ).subscribe();
  }

  toggle() {
    this.isShowAllToggled = !this.isShowAllToggled;

    if(this.searchInput.nativeElement.value === "") {
      this.esraApiClient.getAllCurrentCommonNotesWithReferenceInfo(this.pageSize, this.currentPage, this.isShowAllToggled)
      .pipe(
        indicate(this.searching$),
        )
        .subscribe((commonNotes) => {
          this.dataSource.data = commonNotes;
        });

      this.esraApiClient.getCurrentCommonNoteCount(this.isShowAllToggled)
        .pipe(
          tap(result => {
            this.totalCount = result;
          })
        ).subscribe();
    } else {
      this.search();
    }
  }

  clearSearchText(): void {
    this.esraApiClient.getAllCurrentCommonNotesWithReferenceInfo(this.pageSize, this.currentPage, this.isShowAllToggled)
      .pipe(
        indicate(this.searching$),
        first(),
        tap(commonNotes => {
          this.dataSource.data = commonNotes;
        })
      ).subscribe();

    this.esraApiClient.getCurrentCommonNoteCount(this.isShowAllToggled)
    .pipe(
      tap(result => {
        this.totalCount = result;
      })
    ).subscribe();

    this.searchInput.nativeElement.value = "";
  }

  updateDataSource(pageSize, currentPage) {
    this.esraApiClient.getAllCurrentCommonNotesWithReferenceInfo(pageSize, currentPage, this.isShowAllToggled)
      .pipe(
        indicate(this.searching$),
        first(),
        tap(commonNotes => {
          this.dataSource.data = commonNotes;
        }))
        .subscribe();
  }

  public updatePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.updateDataSource(this.pageSize, this.currentPage);
  }

  protected getFilterPredicate(record: CommonNoteViewModel, parametersMap: Map<string, string>): boolean {
    const searchQuery = parametersMap.get(DictionarySimplePageComponent.SEARCH_QUERY_NAME)?.toLowerCase() || '';
    const isActiveFilter = parametersMap.get(DictionarySimplePageComponent.IS_ACTIVE_NAME);

    const matchesSearchQuery = record.codeId.toString().toLowerCase().includes(searchQuery) || record.note.toLowerCase().includes(searchQuery);

    if (isActiveFilter == null) {
        return matchesSearchQuery;
    } else {
        return matchesSearchQuery && record.isActive === JSON.parse(isActiveFilter);
    }  
  
  }

  protected createNew() {
    window.open(this.url);
  }

  protected editItem(commonNote) {
    localStorage.setItem('commonNote', JSON.stringify(commonNote));
    window.open(this.url);
  }

  protected editItemReferenced(commonNote) {
    localStorage.setItem('commonNote', JSON.stringify(commonNote));
    window.open(this.url);
  }

  protected deleteItem(item: CommonNoteViewModel): void {
    this.dialog.open(DictionaryDeleteComponent, {
      width: '40%',
      height: '23%',
      data: {
        item: item,
        objectName: item.codeId ,
        objectType: 'common note'
      }
    }).afterClosed().subscribe((result) => {
      if(result) {
        this.esraApiClient.deleteCommonNote(item.noteId).subscribe({
          next: () => {
            this.snackBarService.showSnackBar(
              true,
              `Common note "${item.codeId}" successfully deleted.`,
              'mat-snack-bar-info'
            );
            this.searchAll();
          },
          error: (error) => {
            this.snackBarService.showSnackBar(
              true,
              `Sorry, something went wrong. Error trying to delete common note: "${item.codeId}".`,
              'mat-snack-bar-error'
            );
            this.insightsService.logException(error);
          },
        });
      }
    })
  }

  protected getUsageInformation(item): void {
    (this.dialog.open(ServiceNoteUsageComponent, {
      width: '40%',
      height: '40%',
      data: item
    })).afterClosed().subscribe(() => {
      this.searchAll();
    })
  }

  protected updateStatus(item) {
    this.esraApiClient.toggleIsActive(item).subscribe(
      {
        next: () => {
          this.searchAll();
        },
        error: (err) => {
          this.snackBarService.showError(err);
        } 
      }
    );
  }
}
