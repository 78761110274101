import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tap, first } from 'rxjs/operators';
import { indicate } from 'src/app/operators';
import { DictionaryItem, DictionaryItemDefinition, DictionarySimplePageComponent } from 'src/app/shared/components/dictionary-simple-page/dictionary-simple-page.component';
import { EsraPipingAPIClient, ServiceViewModel } from 'src/app/shared/models/autogenerated-piping';
import { ServiceNoteUsageComponent } from '../dialogs/service-note-usage/service-note-usage.component';
import { DictionaryDeleteComponent } from '../dialogs/dictionary-delete/dictionary-delete.component';
import { InsightsService } from 'src/app/shared/services/insights.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-services',
  templateUrl: '../../../../shared/components/dictionary-simple-page/dictionary-simple-page.component.html',
  styleUrls: ['../../../../shared/components/dictionary-simple-page/dictionary-simple-page.component.scss']
})
export class ServicesComponent extends DictionarySimplePageComponent<ServiceViewModel> {
  url = 'piping/serviceAddEdit';

  constructor(
    private esraApiClient: EsraPipingAPIClient,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private insightsService: InsightsService
  ) { super(); }

  protected searchAll() {
    this.esraApiClient.getAllCurrentServices()
      .pipe(
        indicate(this.searching$),
        first(),
        tap(services => { 
          this.totalCount = services.length; 
          this.dataSource.data = services;
        })
      ).subscribe();
  }

  protected initializeDictionaryDefinition(): DictionaryItemDefinition<ServiceViewModel>{
    return {
      displayStatusColumn: true,
      dictionaryItems: [ new DictionaryItem('Last Updated', 'updated', 'standard', (item: ServiceViewModel) =>  this.getDate(item) ),
        new DictionaryItem('Service Detail',  'name', 'extended')
      ]
    };
  }

  protected getDate(item: ServiceViewModel): string {
    return String(item.updated.getMonth() + 1).padStart(2, '0') + '/' + String(item.updated.getDate()).padStart(2, '0') + '/' + item.updated.getFullYear();
  }

  protected getFilterPredicate(record: ServiceViewModel, parametersMap: Map<string, string>): boolean {
    const searchQuery = parametersMap.get(DictionarySimplePageComponent.SEARCH_QUERY_NAME)?.toLowerCase() || '';
    const isActiveFilter = parametersMap.get(DictionarySimplePageComponent.IS_ACTIVE_NAME);

    const matchesSearchQuery = record.updated.toString().toLowerCase().includes(searchQuery) || record.name.toLowerCase().includes(searchQuery);

    if (isActiveFilter == null) {
        return matchesSearchQuery;
    } else {
        return matchesSearchQuery && record.isActive === JSON.parse(isActiveFilter);
    }
  }

  protected getNoMatchMessage(): string {
    return "No services match provided search criteria";    
  }

  protected createNew(): void {
    window.open(this.url);
  }

  protected editItem(service) {
    localStorage.setItem('service', JSON.stringify(service));
    window.open(this.url);
  }
  
  protected editItemReferenced(service) {
    this.snackBarService.showSnackBar(
      true,
      `Service, ${service.name}, cannot be edited because it is in use`
    );
  }

  protected deleteItem(item) {
    this.dialog.open(DictionaryDeleteComponent, {
      width: '40%',
      height: '23%',
      data: {
        item: item,
        objectName: item.name,
        objectType: 'service'
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.esraApiClient.deleteService(item.id).subscribe({
          next: () => {
            this.snackBarService.showSnackBar(
              true,
              `Service "${item.name}" was successfully deleted.`,
              'mat-snack-bar-info'
            );
            this.searchAll();
          },
          error: (error) => {
            this.snackBarService.showSnackBar(
              true,
              `Sorry, something went wrong. Error trying to delete service "${item.name}".`,
              'mat-snack-bar-error'
            );
            this.insightsService.logException(error);
          },
        });
      }
    });
  }

  protected getUsageInformation(item): void {
    (this.dialog.open(ServiceNoteUsageComponent, {
      width: '40%',
      height: '40%',
      data: item
    })).afterClosed().subscribe(() => {
      this.searchAll();
    });
  }

  protected updateStatus(item) {
    this.esraApiClient.updateService(item).subscribe();
  }
}
